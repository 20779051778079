import React from "react"
import { Helmet } from "react-helmet"

import "../styles/global.css"

import Store from "../components/store"
import ContainerMain from "../components/containermain"
import SideBar from "../components/sidebar"
import Wrapper from "../components/wrapper"
import ContainerContent from "../components/containercontent"
import HeaderMobile from "../components/headermobile"
import Footer from "../components/footer"

export default ({ data }) => {
  return (
    <Store>
      <Store.Consumer>
        {({ changeIsHambOpen, isHambOpen }) => (
          <Wrapper resized={() => changeIsHambOpen(false)}>
            <Helmet>
              <meta charSet="utf-8" />
              <title>Objav.sk - Podmienky používania</title>
              <meta name="description" content="metadesc" />
              <style>
                {`body { 
                background: grey;
              `}
              </style>
            </Helmet>
            <HeaderMobile
              closed={isHambOpen}
              bgGradientStart="grey"
              bgGradientEnd="grey"
              menuName="Podmienky používania"
            />
            <ContainerMain>
              <SideBar />
              <ContainerContent>
                <h1>Podmienky používania</h1>
                <p>
                  <strong>
                    Práva vyhradené autorovi na základe autorského práva a práv
                    priemyselného vlastníctva
                  </strong>
                </p>
                <p>
                  Autor poskytnutím predmetnej služby uverejní obsah a služby
                  (vrátane článkov, fotografií, grafík a ostatných materiálov,
                  lay-outu a dizajnu služby, konštrukčných princípov, nápadov a
                  realizácie), ktoré tvoria duchovné vlastníctvo autora a ich
                  prípadné použitie s výnimkou čítania, zobrazenia na obrazovke
                  a k tomu potrebného kopírovania, uloženia na hard disk a
                  tlačenia na iné ako osobné účely nie je dovolené. Tieto
                  oprávnené použitia však nesmú sledovať žiadny obchodný záujem.
                </p>
                <p>
                  Objav.sk je názov domény zaregistrovanej autorom. Používanie
                  tohto označenia vrátane k nemu veľmi podobných výrazov je
                  možné len s predchádzajúcim písomným súhlasom autora.
                </p>
                <p>
                  Autorom poskytnutá služba obsahuje aj linky odkazujúce na
                  webové stránky prevádzkované tretími osobami. Pokiaľ sa autor
                  dozvie o skutočnostiach, podľa ktorých niektorá uverejnená
                  webová stránka porušuje všeobecne záväzné právne predpisy
                  prípadne práva či oprávnené záujmy tretích osôb bezprostredne
                  po tom čo sa o tom dozvie link na danú webovú stránku
                  odstráni. Autor nenesie zodpovednosť za obsah stránok, ktorých
                  linky sú umiestnené na jeho portálu.
                </p>
                <p>
                  Porušenie autorského práva je trestný čin na základe paragrafu
                  281-283 Trestného zákona 300/2005 Z.z. v znení neskorších
                  predpisov a môže sa trestať trestom odňatia slobody až na 5
                  rokov.
                </p>
                <br />
              </ContainerContent>
            </ContainerMain>
            <Footer />
          </Wrapper>
        )}
      </Store.Consumer>
    </Store>
  )
}
